import { Component, Inject, OnInit } from '@angular/core';
import { LocalStoreService } from '@core/services';
import { AuthService } from '@core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { from, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Constants, LocalStorageKeys } from './configs/app.config';
import { IOctaUserModel } from './core/models/octa.model';

@Component({
  selector: 'sra-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'flagship-web';
  userDetails!: IOctaUserModel;

  constructor(
    private readonly translateService: TranslateService,
    private readonly appConstants: Constants,
    @Inject(OKTA_AUTH) private readonly _oktaAuth: OktaAuth,
    private readonly _oktaStateService: OktaAuthStateService,
    private readonly localStore: LocalStoreService,
    private readonly authService: AuthService,
    private readonly router:Router
  ) {

    // This language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use(this.appConstants.defaultLanguage);

  }

  isAuthenticated$!: Observable<boolean>;
  private authSubscription: Subscription = new Subscription;
  ngOnInit(): void {
    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    // Subscribe to the authentication state
    this.authSubscription = this.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        const authToken = this._oktaAuth.getAccessToken();
        this.localStore.store(LocalStorageKeys.AccessToken, authToken)
        this.getUserDetails().subscribe((user: IOctaUserModel) => {
          this.localStore.store(LocalStorageKeys.LoggedInUserDetails, user);
          this.authService.onLoginSuccess(user);
          this.router.navigate(['/clients'])
        });
      }
    });
  }

  // method to get okta user details
   getUserDetails(): Observable<any> {
    return from(this._oktaAuth.getUser()).pipe(
      map(user => user)
    );
  }
}
