import { Component, Input } from '@angular/core';

@Component({
  selector: 'sra-svg-icons',
  templateUrl: './svg-icons.component.html',
  styleUrls: ['./svg-icons.component.scss']
})
export class SvgIconsComponent {
   @Input() name: string | undefined;

  @Input() className: string | undefined;

  @Input() focusable: string | undefined;

  @Input() ariaHidden: string | undefined;

  @Input() title: string | undefined;

}
