<svg class="visually-hidden" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <symbol id="icon-left-arrow" viewBox="0 0 20 20" fill="none">
      <path d="M7.9763 4.94189L2.91797 10.0002L7.9763 15.0586" stroke="currentColor" strokeWidth="1.25"
        strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.0816 10H3.05664" stroke="currentColor" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round"
        strokeLinejoin="round" />
    </symbol>
    <symbol id="icon-timer" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.99967 4.00016V8.00016L10.6663 9.3335M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z"
        stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </symbol>
    <symbol id="icon-light" viewBox="0 0 18 18" fill="none">
      <rect width="18" height="18" rx="9" fill="currentColor" />
      <g clip-path="url(#clip0_1040_2109)">
        <path
          d="M9 4.125V5.375M9 12V14M5.875 9H4.125M13.625 9H12.875M12.2286 12.2286L11.875 11.875M12.3321 5.70789L11.625 6.415M5.46079 12.5392L6.875 11.125M5.56434 5.60434L6.625 6.665"
          stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1040_2109">
          <rect width="12" height="12" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </symbol>
    <symbol id="icon-tick-white" viewBox="0 0 12 10" fill="none">
      <path
        d="M11.0078 1.01503C10.6762 0.661655 10.1331 0.661655 9.80156 1.01503L4.08723 7.09991L2.19844 5.0866C1.86692 4.73322 1.32377 4.73322 0.99225 5.0866C0.66925 5.4309 0.66925 5.98143 0.99225 6.32572L3.48703 8.98496C3.81855 9.33834 4.36169 9.33834 4.69322 8.98496L11.0078 2.25415C11.3308 1.90986 11.3308 1.35933 11.0078 1.01503Z"
        fill="white" stroke="white" strokeWidth="0.5" />
    </symbol>
    <symbol id="icon-tick-fill" viewBox="0 0 12 13" fill="none">
      <path
        d="M6 12.5C9.31371 12.5 12 9.81371 12 6.5C12 3.18629 9.31371 0.5 6 0.5C2.68629 0.5 0 3.18629 0 6.5C0 9.81371 2.68629 12.5 6 12.5Z"
        fill="currentColor" />
      <path
        d="M4.74993 8.2373L3.3041 6.79147C3.1416 6.62897 2.8791 6.62897 2.7166 6.79147C2.5541 6.95397 2.5541 7.21647 2.7166 7.37897L4.45827 9.12064C4.62077 9.28314 4.88327 9.28314 5.04577 9.12064L9.4541 4.7123C9.6166 4.5498 9.6166 4.2873 9.4541 4.1248C9.2916 3.9623 9.0291 3.9623 8.8666 4.1248L4.74993 8.2373Z"
        fill="white" />
    </symbol>
    <symbol id="icon-search" viewBox="0 0 18 18" fill="none">
      <path
        d="M16.0154 17.0501L10.2615 11.2962C9.76152 11.7091 9.18652 12.0321 8.53652 12.2655C7.88652 12.4988 7.21409 12.6155 6.51922 12.6155C4.81005 12.6155 3.36354 12.0237 2.17967 10.8401C0.995803 9.65655 0.40387 8.21041 0.40387 6.50169C0.40387 4.79296 0.995653 3.34628 2.17922 2.16167C3.36279 0.977065 4.80894 0.384766 6.51767 0.384766C8.22639 0.384766 9.67305 0.9767 10.8577 2.16057C12.0423 3.34443 12.6346 4.79095 12.6346 6.50012C12.6346 7.21422 12.5147 7.89627 12.275 8.54627C12.0352 9.19627 11.7153 9.76165 11.3153 10.2424L17.0942 16.0213C17.2327 16.157 17.3019 16.3237 17.3019 16.5212C17.3019 16.7187 17.2236 16.8957 17.0671 17.0522C16.9236 17.1957 16.7487 17.2674 16.5423 17.2674C16.3359 17.2674 16.1603 17.195 16.0154 17.0501ZM6.51922 11.1155C7.80769 11.1155 8.89904 10.6684 9.79327 9.77417C10.6875 8.87993 11.1346 7.78858 11.1346 6.50012C11.1346 5.21165 10.6875 4.1203 9.79327 3.22607C8.89904 2.33183 7.80769 1.88472 6.51922 1.88472C5.23075 1.88472 4.1394 2.33183 3.24517 3.22607C2.35095 4.1203 1.90384 5.21165 1.90384 6.50012C1.90384 7.78858 2.35095 8.87993 3.24517 9.77417C4.1394 10.6684 5.23075 11.1155 6.51922 11.1155Z"
        fill="currentColor" />
    </symbol>
    <symbol id="icon-filter" viewBox="0 0 22 20" fill="none">
      <path
        d="M1 2.6C1 2.03995 1 1.75992 1.10899 1.54601C1.20487 1.35785 1.35785 1.20487 1.54601 1.10899C1.75992 1 2.03995 1 2.6 1H19.4C19.9601 1 20.2401 1 20.454 1.10899C20.6422 1.20487 20.7951 1.35785 20.891 1.54601C21 1.75992 21 2.03995 21 2.6V3.26939C21 3.53819 21 3.67259 20.9672 3.79756C20.938 3.90831 20.8901 4.01323 20.8255 4.10776C20.7526 4.21443 20.651 4.30245 20.4479 4.4785L14.0521 10.0215C13.849 10.1975 13.7474 10.2856 13.6745 10.3922C13.6099 10.4868 13.562 10.5917 13.5328 10.7024C13.5 10.8274 13.5 10.9618 13.5 11.2306V16.4584C13.5 16.6539 13.5 16.7517 13.4685 16.8363C13.4406 16.911 13.3953 16.9779 13.3363 17.0315C13.2695 17.0922 13.1787 17.1285 12.9971 17.2012L9.59711 18.5612C9.22957 18.7082 9.0458 18.7817 8.89827 18.751C8.76927 18.7242 8.65605 18.6476 8.58325 18.5377C8.5 18.4122 8.5 18.2142 8.5 17.8184V11.2306C8.5 10.9618 8.5 10.8274 8.46715 10.7024C8.43805 10.5917 8.39014 10.4868 8.32551 10.3922C8.25258 10.2856 8.15102 10.1975 7.94789 10.0215L1.55211 4.4785C1.34898 4.30245 1.24742 4.21443 1.17449 4.10776C1.10986 4.01323 1.06195 3.90831 1.03285 3.79756C1 3.67259 1 3.53819 1 3.26939V2.6Z"
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </symbol>
    <symbol id="icon-chevron-right" viewBox="0 0 6 10" fill="none">
      <path
        d="M0.394239 9.27232C0.273503 9.1516 0.213135 9.00524 0.213135 8.83324C0.213135 8.66124 0.273503 8.51488 0.394239 8.39416L3.78847 4.99991L0.394239 1.60566C0.273503 1.48493 0.213135 1.33857 0.213135 1.16657C0.213135 0.994574 0.273503 0.848213 0.394239 0.727491C0.514962 0.606755 0.661323 0.546387 0.833323 0.546387C1.00532 0.546387 1.15168 0.606755 1.27241 0.727491L5.01759 4.47268C5.09559 4.55066 5.15061 4.63293 5.18266 4.71947C5.21471 4.806 5.23074 4.89948 5.23074 4.99991C5.23074 5.10034 5.21471 5.19382 5.18266 5.28034C5.15061 5.36689 5.09559 5.44915 5.01759 5.52714L1.27241 9.27232C1.15168 9.39306 1.00532 9.45343 0.833323 9.45343C0.661323 9.45343 0.514962 9.39306 0.394239 9.27232Z"
        fill="currentColor" />
    </symbol>
    <symbol id="icon-close" viewBox="0 0 12 12" fill="none">
      <path fill-rule="evenodd" clipRule="evenodd"
        d="M6.00002 5.29295L10.6465 0.646484L11.3536 1.35359L6.70713 6.00006L11.3536 10.6465L10.6465 11.3536L6.00002 6.70716L1.35359 11.3536L0.646484 10.6465L5.29291 6.00006L0.646486 1.35367L1.35359 0.64656L6.00002 5.29295Z"
        fill="currentColor" />
    </symbol>
    <symbol id="icon-go-to" viewBox="0 0 9 9" fill="none">
      <path d="M0.5 1L8 1M8 1V8.5M8 1L0.5 8.5" stroke="currentColor" />
    </symbol>
    <symbol id="icon-attachment" viewBox="0 0 14 15" fill="none">
      <path
        d="M13.1015 6.76647L7.09108 12.7769C5.72425 14.1437 3.50817 14.1437 2.14134 12.7769C0.774502 11.41 0.774502 9.19396 2.14134 7.82713L8.15174 1.81672C9.06297 0.905495 10.5404 0.905495 11.4516 1.81672C12.3628 2.72794 12.3628 4.20533 11.4516 5.11655L5.67687 10.8913C5.22126 11.3469 4.48257 11.3469 4.02695 10.8913C3.57134 10.4356 3.57134 9.69695 4.02695 9.24134L9.09455 4.17374"
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </symbol>
    <symbol id="icon-delete" viewBox="0 0 24 24" fill="none">
      <path
        d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6"
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </symbol>
    <symbol id="icon-eye" viewBox="0 0 24 24" fill="none">
      <path
        d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </symbol>
    <symbol id="icon-dislike" viewBox="0 0 12 12" fill="none">
      <path
        d="M8.49954 1V6.5M10.9995 4.9V2.6C10.9995 2.03995 10.9995 1.75992 10.8905 1.54601C10.7947 1.35785 10.6417 1.20487 10.4535 1.10899C10.2396 1 9.95959 1 9.39954 1H4.05854C3.3278 1 2.96243 1 2.66733 1.13372C2.40724 1.25157 2.18619 1.44121 2.03016 1.68036C1.85312 1.9517 1.79757 2.31282 1.68645 3.03506L1.42491 4.73506C1.27836 5.68766 1.20508 6.16395 1.34644 6.53456C1.47051 6.85985 1.70386 7.13185 2.0065 7.30393C2.3513 7.5 2.8332 7.5 3.79701 7.5H4.19954C4.47956 7.5 4.61958 7.5 4.72653 7.5545C4.82061 7.60243 4.8971 7.67892 4.94504 7.773C4.99954 7.87996 4.99954 8.01997 4.99954 8.3V9.76708C4.99954 10.448 5.55154 11 6.23246 11C6.39487 11 6.54205 10.9044 6.60801 10.7559L8.28839 6.97509C8.36482 6.80312 8.40303 6.71714 8.46344 6.65409C8.51683 6.59836 8.5824 6.55575 8.65501 6.52959C8.73716 6.5 8.83125 6.5 9.01944 6.5H9.39954C9.95959 6.5 10.2396 6.5 10.4535 6.39101C10.6417 6.29513 10.7947 6.14215 10.8905 5.95399C10.9995 5.74008 10.9995 5.46005 10.9995 4.9Z"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </symbol>
    <symbol id="icon-like" viewBox="0 0 12 12" fill="none">
      <path
        d="M3.5 11V5.5M1 6.5V10C1 10.5523 1.44772 11 2 11H8.71312C9.45348 11 10.0831 10.4598 10.1957 9.72809L10.7341 6.22809C10.8739 5.31945 10.1709 4.5 9.25158 4.5H7.5C7.22386 4.5 7 4.27614 7 4V2.23292C7 1.552 6.448 1 5.76708 1C5.60467 1 5.45749 1.09565 5.39153 1.24406L3.63197 5.20307C3.55172 5.38363 3.37266 5.5 3.17506 5.5H2C1.44772 5.5 1 5.94772 1 6.5Z"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </symbol>
    <symbol id="icon-cloud-slash" viewBox="0 0 16 17" fill="none">
      <path
        d="M14.4666 11.2454C14.5956 10.9106 14.6663 10.5469 14.6663 10.1667C14.6663 8.60429 13.472 7.32086 11.9465 7.17958C11.6345 5.28142 9.98618 3.83333 7.99967 3.83333C7.70197 3.83333 7.41185 3.86586 7.13269 3.92755M4.85798 5.35723C4.44845 5.87613 4.16473 6.49892 4.05284 7.17958C2.52735 7.32086 1.33301 8.60429 1.33301 10.1667C1.33301 11.8235 2.67615 13.1667 4.33301 13.1667H11.6663C11.9704 13.1667 12.2639 13.1214 12.5404 13.0373M1.99967 2.5L13.9997 14.5"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />

    </symbol>

    <symbol id="icon-building" viewBox="0 0 16 14" fill="none">
      <path
        d="M5.00065 6.33333H3.06732C2.69395 6.33333 2.50727 6.33333 2.36466 6.406C2.23922 6.46991 2.13723 6.5719 2.07331 6.69734C2.00065 6.83995 2.00065 7.02663 2.00065 7.4V13M11.0007 6.33333H12.934C13.3074 6.33333 13.494 6.33333 13.6366 6.406C13.7621 6.46991 13.8641 6.5719 13.928 6.69734C14.0007 6.83995 14.0007 7.02663 14.0007 7.4V13M11.0007 13V3.13333C11.0007 2.3866 11.0007 2.01323 10.8553 1.72801C10.7275 1.47713 10.5235 1.27316 10.2726 1.14532C9.98742 1 9.61405
         1 8.86732 1H7.13398C6.38725 1 6.01388 1 5.72866 1.14532C5.47778 1.27316 5.27381 1.47713 5.14598 1.72801C5.00065 2.01323 5.00065 2.3866 5.00065 3.13333V13M14.6673 13H1.33398M7.33398 3.66667H8.66732M7.33398 6.33333H8.66732M7.33398 9H8.66732"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />

    </symbol>
    <symbol id="icon-back-arrow" viewBox="0 0 20 20" fill="none">
      <path d="M7.9763 4.94189L2.91797 10.0002L7.9763 15.0586" strokeWidth="1.25" stroke-miterlimit="10"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.0816 10H3.05664" strokeWidth="1.25" stroke-miterlimit="10" stroke="currentColor"
        strokeLinecap="round" strokeLinejoin="round" />
    </symbol>

    <symbol id="icon-location" viewBox="0 0 16 16" fill="none">
        <path d="M7.99992 8.95346C9.14867 8.95346 10.0799 8.02221 10.0799 6.87346C10.0799 5.7247 9.14867 4.79346 7.99992 4.79346C6.85117 4.79346 5.91992 5.7247 5.91992 6.87346C5.91992 8.02221 6.85117 8.95346 7.99992 8.95346Z" stroke="currentColor"
       />
        <path d="M2.41379 5.66016C3.72712 -0.113169 12.2805 -0.106502 13.5871 5.66683C14.3538 9.0535 12.2471 11.9202 10.4005 13.6935C9.06046 14.9868 6.94046 14.9868 5.59379 13.6935C3.75379 11.9202 1.64712 9.04683 2.41379 5.66016Z" stroke="currentColor"
       />
    </symbol>
    <symbol id="icon-profile" viewBox="0 0 16 16" fill="none">
        <path d="M8.07917 8.51999C8.0325 8.51332 7.9725 8.51332 7.91917 8.51999C6.74583 8.47999 5.8125 7.51999 5.8125 6.33999C5.8125 5.13332 6.78583 4.15332 7.99917 4.15332C9.20583 4.15332 10.1858 5.13332 10.1858 6.33999C10.1792 7.51999 9.2525 8.47999 8.07917 8.51999Z"         stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.4925 12.92C11.3059 14.0067 9.73253 14.6667 7.99919 14.6667C6.26586 14.6667 4.69253 14.0067 3.50586 12.92C3.57253 12.2934 3.97253 11.68 4.68586 11.2C6.51253 9.98671 9.49919 9.98671 11.3125 11.2C12.0259 11.68 12.4259 12.2934 12.4925 12.92Z"        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z"         stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        
  </symbol>
  <symbol id="icon-phone" viewBox="0 0 16 16" fill="none">
      <path d="M14.6473 12.2202C14.6473 12.4602 14.594 12.7068 14.4806 12.9468C14.3673 13.1868 14.2207 13.4135 14.0273 13.6268C13.7007 13.9868 13.3407 14.2468 12.934 14.4135C12.534 14.5802 12.1007 14.6668 11.634 14.6668C10.954 14.6668 10.2273 14.5068 9.46065 14.1802C8.69398 13.8535 7.92732 13.4135 7.16732 12.8602C6.40065 12.3002 5.67398 11.6802 4.98065 10.9935C4.29398 10.3002 3.67398 9.5735 3.12065 8.8135C2.57398 8.0535 2.13398 7.2935 1.81398 6.54016C1.49398 5.78016 1.33398 5.0535 1.33398 4.36016C1.33398 3.90683 1.41398 3.4735 1.57398 3.0735C1.73398 2.66683 1.98732 2.2935 2.34065 1.96016C2.76732 1.54016 3.23398 1.3335 3.72732 1.3335C3.91398 1.3335 4.10065 1.3735 4.26732 1.4535C4.44065 1.5335 4.59398 1.6535 4.71398 1.82683L6.26065 4.00683C6.38065 4.1735 6.46732 4.32683 6.52732 4.4735C6.58732 4.6135 6.62065 4.7535 6.62065 4.88016C6.62065 5.04016 6.57398 5.20016 6.48065 5.3535C6.39398 5.50683 6.26732 5.66683 6.10732 5.82683L5.60065 6.3535C5.52732 6.42683 5.49398 6.5135 5.49398 6.62016C5.49398 6.6735 5.50065 6.72016 5.51398 6.7735C5.53398 6.82683 5.55398 6.86683 5.56732 6.90683C5.68732 7.12683 5.89398 7.4135 6.18732 7.76016C6.48732 8.10683 6.80732 8.46016 7.15398 8.8135C7.51398 9.16683 7.86065 9.4935 8.21398 9.7935C8.56065 10.0868 8.84732 10.2868 9.07398 10.4068C9.10732 10.4202 9.14732 10.4402 9.19398 10.4602C9.24732 10.4802 9.30065 10.4868 9.36065 10.4868C9.47398 10.4868 9.56065 10.4468 9.63398 10.3735L10.1407 9.8735C10.3073 9.70683 10.4673 9.58016 10.6207 9.50016C10.774 9.40683 10.9273 9.36016 11.094 9.36016C11.2207 9.36016 11.354 9.38683 11.5007 9.44683C11.6473 9.50683 11.8007 9.5935 11.9673 9.70683L14.174 11.2735C14.3473 11.3935 14.4673 11.5335 14.5406 11.7002C14.6073 11.8668 14.6473 12.0335 14.6473 12.2202Z"   stroke="currentColor" stroke-miterlimit="10"/>
      
  </symbol>
  <symbol id="icon-mail" viewBox="0 0 16 16" fill="none">
      <path d="M11.334 13.6668H4.66732C2.66732 13.6668 1.33398 12.6668 1.33398 10.3335V5.66683C1.33398 3.3335 2.66732 2.3335 4.66732 2.3335H11.334C13.334 2.3335 14.6673 3.3335 14.6673 5.66683V10.3335C14.6673 12.6668 13.334 13.6668 11.334 13.6668Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.3327 6L9.24601 7.66667C8.55935 8.21333 7.43268 8.21333 6.74601 7.66667L4.66602 6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" stroke-miterlimit="10" />
          
</symbol>
<symbol id="icon-country" viewBox="0 0 16 13" fill="none">
    <path d="M5.00065 3.16667H6.83398M5.00065 5.83333H6.83398M5.00065 8.5H6.83398M9.16732 3.16667H11.0007M9.16732 5.83333H11.0007M9.16732 8.5H11.0007M13.334 12.5V2.63333C13.334 1.8866 13.334 1.51323 13.1887 1.22801C13.0608 0.97713 12.8569 0.773156 12.606 0.645325C12.3208 0.5 11.9474 0.5 11.2007 0.5H4.80065C4.05391 0.5 3.68055 0.5 3.39533 0.645325C3.14445 0.773156 2.94047 0.97713 2.81264 1.22801C2.66732 1.51323 2.66732 1.8866 2.66732 2.63333V12.5M14.6673 12.5H1.33398" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
              
</symbol>
  </defs>
</svg>