import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sra-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  @Input() id = '';
  @Input() name = '';
  @Input() label = '';
  @Input() checked = false;
  @Input() disabled = false;
  @Input() value!: string;
  @Output() optionChange = new EventEmitter<string>();

  onRadioChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.checked) {
      this.optionChange.emit(input.value);
    }
  }
}
