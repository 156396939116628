import { Component } from '@angular/core';

@Component({
  selector: 'sra-icons-library',
  templateUrl: './icons-library.component.html',
  styleUrls: ['./icons-library.component.scss']
})
export class IconsLibraryComponent {

}
