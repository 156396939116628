import { Component, ChangeDetectorRef } from '@angular/core';
import { PreloaderService } from '@core/services/preloader.service';

@Component({
  selector: 'sra-preloader',
  templateUrl: './pre-loader.component.html',
  styleUrls: ['./pre-loader.component.scss']
})
export class PreloaderComponent {

    public active: boolean | undefined;
    public message: any;
    public constructor(public preloader: PreloaderService,
                       private readonly changeDetectorRef: ChangeDetectorRef) {
        preloader.status.subscribe((status: boolean) => {
            this.message = this.preloader.message ? this.preloader.message : null;
            this.active = status;
            this.changeDetectorRef.detectChanges();
        });
    }
}
