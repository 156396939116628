import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import {
  IAssessment,
  IAssessmentProperties,
  IOverallScore,
  IStartAssessment,
  ISubmitAssessment,
  ISubmitAttachments,
  ISyncQuestion,
  ISyncMainTab,
} from '@core/models/assessment.model';
import { HttpClient } from '@angular/common/http';
import { DBStoreNames, LocalStorageKeys } from '../../configs/app.config';
import { IndexedDbService, LocalStoreService } from '../services/index';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  private readonly changesMade = new BehaviorSubject<boolean>(false);
  private readonly assessmentProperties =
    new BehaviorSubject<IAssessmentProperties>({} as IAssessmentProperties);
  changesMade$: Observable<boolean> = this.changesMade.asObservable();
  assessmentProperties$: Observable<IAssessmentProperties> =
    this.assessmentProperties.asObservable();
  constructor(
    private readonly localService: LocalStoreService,
    private readonly httpClient: HttpClient,
    private readonly dbService: IndexedDbService
  ) { }

  /**
   * function to set view mode
   *
   * @param {boolean} value
   * @memberof AssessmentService
   */
  setChangesMadeStatus(value: boolean) {
    this.changesMade.next(value);
  }

  /**
   * function to update assessment properties
   *
   * @param {IAssessmentProperties} properties
   * @memberof AssessmentService
   */
  updateAssessmentProperties(properties: IAssessmentProperties): void {
    const updatedProperties = {
      ...this.localService.get(LocalStorageKeys.ASSESSMENT_PROPERTIES),
      ...properties,
    };
    this.localService.store(LocalStorageKeys.ASSESSMENT_PROPERTIES, updatedProperties);
  }

  /**
   * function to start assessment locally
   *
   * @memberof AssessmentService
   */
  startAssessmentLocal() {
    this.localService.store(LocalStorageKeys.ASSESSMENT_STATUS, true);
    this.localService.store(LocalStorageKeys.ViewOnlyMode, false);
  }

  /**
   * function to end locally started assessment
   *
   * @memberof AssessmentService
   */
  endAssessmentLocal() {
    this.localService.store(LocalStorageKeys.ASSESSMENT_STATUS, false);
    this.localService.store(LocalStorageKeys.ViewOnlyMode, true);
  }

  /**
   * function to return assessment status
   *
   * @return {*}
   * @memberof AssessmentService
   */
  getAssessmentStatus() {
    return this.localService.get(LocalStorageKeys.ASSESSMENT_STATUS);
  }


  /**
   * calls API with assessment ID to get questions to continue assessment
   *
   * @param {number} assessmentID
   * @return {*}  {Observable<IAssessment>}
   * @memberof AssessmentService
   */

  /* istanbul ignore next */
  continueAssessment(assessmentID: number): Observable<IAssessment> {
    return this.httpClient.get<IAssessment>(
      `continue-assessment?AssessmentID=${assessmentID}`
    );
  }

  /**
   * calls API with assessment ID to get questions to continue assessment
   *
   * @param {IStartAssessment} startAssessmentModel
   * @return {*}  {Observable<IAssessment>}
   * @memberof AssessmentService
   */

  /* istanbul ignore next */
  startAssessment(
    startAssessmentModel: IStartAssessment
  ): Observable<IAssessment> {
    return this.httpClient.post<IAssessment>(
      `start-assessment`,
      startAssessmentModel
    );
  }

  /**
   * call backend to get overall score of an assessment
   *
   * @param {number} assessmentID
   * @return {*}  {Observable<IOverallScore>}
   * @memberof AssessmentService
   */

  /* istanbul ignore next */
  getOverallScore(assessmentID: number): Observable<IOverallScore> {
    return this.httpClient.get<IOverallScore>(
      `completed-section-scores?AssessmentID=${assessmentID}`
    );
  }

  /**
   * upload chunks of images to backend
   *
   * @param {ISubmitAttachments} chunk
   * @return {*}  {Observable<string>}
   * @memberof AssessmentService
   */
  /* istanbul ignore next */
  uploadChunks(chunk: ISubmitAttachments): Observable<string> {
    return this.httpClient.post<string>('/upload-attachments', chunk);
  }

  /**
   * submit and sync assessment data to system
   *
   * @param {ISubmitAssessment} assessmentData
   * @return {*}  {Observable<string>}
   * @memberof AssessmentService
   */
  /* istanbul ignore next */
  syncAssessmentData(assessmentData: ISubmitAssessment): Observable<string> {
    return this.httpClient.post<string>(
      '/sync-assessment-data',
      assessmentData
    );
  }

  /**
   * clear assessment
   *
   * @param {number} assessmentID
   * @return {*}  {Observable<boolean>}
   * @memberof AssessmentService
   */
  /* istanbul ignore next */
  clearAssessment(assessmentID: number): Observable<boolean> {
    return this.httpClient.post<boolean>('/clear-assessment-data', {
      assessmentID,
    });
  }


  /*/**
   * Sync a main tab responses
   *
   * @param {ISyncMainTab} tabSync
   * @return {*}  {Observable<string>}
   * @memberof AssessmentService
   */
  /* istanbul ignore next */
  saveDraft(tabSync: ISyncMainTab): Observable<string> {
    return this.httpClient.post<string>('/save-all-draft-answers', tabSync);
  }

  /**
   * loop and clear all indexed db stores
   *
   * @memberof AssessmentService
   */
  clearDBStores(): void {
    const dbStoreNames = Object.values(DBStoreNames);
    dbStoreNames.forEach((storeName) => {
      this.dbService
        .clearStore(storeName)
        .pipe(take(1))
        .subscribe(() => { });
    });
  }

  /**
   * submit and sync assessment data to system
   *
   * @param {ISubmitAssessment} assessmentData
   * @return {*}  {Observable<string>}
   * @memberof AssessmentService
   */
  syncQuestionAttachment(questionData: ISyncQuestion): Observable<ISyncQuestion> {
    return this.httpClient.post<ISyncQuestion>(
      '/sync-question-attachment',
      questionData
    );
  }
}
