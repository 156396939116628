import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Constants } from '../../configs/app.config';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  key = this.keys.key
  iv = this.keys.iv;

  constructor(private readonly keys: Constants) { }

  /**
   * Method to encrypt the data received from msalService
   *
   * @param rawData string type data
   * @returns Encrypted data
   */
  encrypt(rawData: any): string {
    return CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(rawData),
      this.key,
      this.utf8Conversion()
    ).toString()
  }

  /**
   * Method To decrypt the encrypted data
   *
   * @param rawData Encrypted data To be decrypted
   * @returns Decrypted data.
   */
  decrypt(rawData: string | CryptoJS.lib.CipherParams) {
    return CryptoJS.AES.decrypt(
      rawData,
      this.key,
      this.utf8Conversion()
    ).toString(CryptoJS.enc.Utf8);
  }

  /**
   * Converts the value stored in iv using Utf8 conversion by CryptoJs
   *
   * @returns utf8 converted data
   */
  utf8Conversion(): any {
    return ({
      iv: this.iv
    })
  }

  /**
   * Conversion using md5 conversion by CryptoJs
   *
   * @param key key to be converted
   * @returns Converted data
   */
  md5(key: any): string {
    return CryptoJS.MD5(key).toString()
  }
}
