import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sra-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  @Input() label: string | undefined = undefined;
  @Input() ariaLabel = '';
  @Input() disabled = false;
  @Input() id: string | undefined = undefined;
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() hierarchy: 'primary' | 'secondary' | 'link' | 'white' =
    'primary';
  @Input() iconName: string | undefined = undefined;
  @Input() iconPlacement: 'right' | 'left' = 'left';

  @Output() buttonClick = new EventEmitter<void>();

  handleClick(event: Event): void {
    if (!this.disabled) {
      this.buttonClick.emit();
    }
    event.stopPropagation();
  }
}
