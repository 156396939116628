import { Component, Input, OnChanges } from '@angular/core';
import { Constants } from '../../../../app/configs/app.config';

@Component({
  selector: 'sra-rating-display',
  templateUrl: './rating-display.component.html',
  styleUrls: ['./rating-display.component.scss']
})
export class RatingDisplayComponent implements OnChanges {
  constructor(
    private readonly appConstants : Constants
  ) {}
  @Input() min = 0;
  @Input() max = 0;
  @Input() current = 0;


  needleAngle = 0;

  ngOnChanges(): void {
    this.calculateNeedleAngle();
  }

  calculateNeedleAngle(): void {
    const range = this.max - this.min;
    const adjustedCurrent = this.current - this.min;
    const percentage = adjustedCurrent / range;
    const startAngle = -112;
    const endAngle = 32;
    this.needleAngle = startAngle + percentage * (endAngle - startAngle);
  }
}
