import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sra-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnChanges{
  @Input() min = 0; // Minimum value of the progress
  @Input() max = 100; // Maximum value of the progress
  @Input() current = 0; // Current value of the progress
  @Input() progress = 0; // Current progress value (0 to 100)
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() color: 'green' | 'yellow' | 'red' = 'green'; // Color of the progress bar

  // eslint-disable-next-line no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.calculateProgress();
  }

  calculateProgress(): void {
    // Ensure that max is greater than min
    if (this.max > this.min) {
      this.progress = ((this.current - this.min) / (this.max - this.min)) * 100;
    } else {
      this.progress = 0; // If max <= min
    }
  }
}
