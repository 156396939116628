import { Injectable } from '@angular/core';
import { isArray, forEach, upperFirst } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Constants, INotify, Notifications, NotificationsHeading } from '../../configs/app.config'

@Injectable({
    providedIn: 'root'
})
export class NotifyService {
    private readonly toastOptions: any;
    constructor(
        private readonly toastrService: ToastrService,
        private readonly appConstants: Constants,
        private readonly translateService: TranslateService
    ) {
        this.toastOptions = {
            closeButton: true,
            timeOut: this.appConstants.notificationTimeout,
            positionClass: 'toast-top-right',
            enableHtml: true,
            tapToDismiss: false
        };
        /*** Uncomment if notify not needed in redirection
        router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                this.toastyService.clearAll();
            }
        });**/
    }

    getMessage(heading: NotificationsHeading, message: Notifications | string): string {
        // eslint-disable-next-line max-len
        return `<div class='toast-wrapper'><h1 class='toast-header'>${heading}</h1><span class='toast-content'>${message}<span></div>`
    }

    show(status: number, heading: NotificationsHeading, message: Notifications | string, notifyObject?: INotify[]) {
        let type: 'success' | 'warning' | 'error' | 'info';
        let msg = '';
        switch (status) {
            case 0:
                type = 'success';
                break;
            case 1:
                type = 'warning';
                break;
            case 2:
                type = 'error';
                break;
            case 3:
                type = 'info';
                break;
            default:
                type = 'info';
        }
        if (isArray(notifyObject)) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            forEach(notifyObject, (notification) => {
                msg += `${notification}<br>`;
            });
        } else {
            msg = this.translateService.instant(message);
        }

        if (msg !== '') {
            const notification = this.getMessage(heading, message);
            const title = upperFirst(type);
            this.toastrService[type](notification, title, this.toastOptions);
        }
    }
}
