import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbService {
  constructor(private readonly idbService: NgxIndexedDBService) {}

  save<T>(storeName: string, entity: T, key?: number): Observable<T> {
    if (key) {
      return this.idbService.update(storeName, entity);
    } else {
      return this.idbService.add(storeName, entity);
    }
  }

  getById<T>(storeName: string, id: number): Observable<T> {
    return this.idbService.getByKey(storeName, id);
  }

  getAll<T>(storeName: string): Observable<T[]> {
    return this.idbService.getAll(storeName);
  }

  /* istanbul ignore next */
  getAllByIndex(storeName: string, indexName: string, key: any) {
    return this.idbService.getAllByIndex(
      storeName,
      indexName,
      IDBKeyRange.only(key)
    );
  }

  bulkAdd(storeName: string, values: any[]): Observable<number[]> {
    return this.idbService.bulkAdd(storeName, values);
  }

  deleteDB() {
    return this.idbService.deleteDatabase();
  }

  clearStore(storeName: string) {
    return this.idbService.clear(storeName);
  }
}
