<div
  class="progress-bar-container"
  [ngClass]="{
    'sm': size === 'sm',
    'md': size === 'md',
    'lg': size === 'lg',    

  }"
>
  <div
    class="progress-bar"
    [ngStyle]="{
      'width': progress + '%',
    }"
    [ngClass]="{

    'green': color === 'green',
    'yellow': color === 'yellow',
    'red': color === 'red',    
  }"
  ></div>
</div>
