import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'siteDetailsLabel',
})
export class SiteDetailsLabelPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return 'N/A';
    const spacedString = value.replace(/([a-z])([A-Z])/g, '$1 $2');
    return spacedString
      .split(' ')
      .map((word, index) => {
        if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        } else {
          return word.toLowerCase();
        }
      })
      .join(' ');
  }
}
