import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocalStoreService, NotifyService, PreloaderService } from '@core/services';
import { environment } from '@environment/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { catchError, finalize, Observable, of, tap, throwError } from 'rxjs';
import { Constants, HttpErrorCode, LocalStorageKeys, NotificationsHeading, ToastTypes } from '../../configs/app.config';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  errorMessageKey = 'title';
  errorMessage = 'PAGE.TOAST_MESSAGE.ERROR';

  /* istanbul ignore next */
  constructor(
    private readonly localStoreService: LocalStoreService,
    private readonly notify: NotifyService,
    private readonly preloader: PreloaderService,
    private readonly appConstants: Constants,
    @Inject(OKTA_AUTH) private readonly _oktaAuth: OktaAuth
  ) { }

  /**
   * Method to intercept the http requests.
   *
   * @param req
   * @param next
   * @returns intercepted request.
   */
  /* istanbul ignore next */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const config = JSON.parse(req.params.get('_config') ?? '{}');

    return next.handle(this.setRequestHeader(req, config)).pipe(
      tap((event) => this.stopPreloaderForResponse(event, config)),
      catchError((err) => {
        if (!config.noLoader) {
          this.preloader.stop();
        }
        if (err instanceof HttpErrorResponse) {
          if (err.status === HttpErrorCode.FORBIDDEN) {
            this.localStoreService.clear();
          }
          return this.onSubscribeError(err, config);
        } else {
          return of(err);
        }
      }),
      finalize(() => { })
    );
  }

  /**
   * Method to set the request heaader.
   *
   * @param req
   * @param config
   * @returns request body
   */
  /* istanbul ignore next */
  setRequestHeader(req: HttpRequest<any>, config: any): any {
    this.requestInterceptor(config.noLoader);
    if (
      req.url.includes('assets/i18n') ||
      req.url.includes('https://') ||
      req.url.includes('http://') ||
      req.url.includes('assets/sheets/') ||
      req.url.includes('assets/config/config.json')
    ) {
      return req;
    } else {
      const accessToken = this._oktaAuth.getAccessToken();
      const existingToken = this.localStoreService.get(LocalStorageKeys.AccessToken);
      if (accessToken !== existingToken) {
        this._oktaAuth.signOut();
      }
      req = req.clone({ params: req.params.delete('_config') });
      req = req.clone({
        url: `${environment.APIBasePath}${this.removeSlashes(req.url)}`,
      });

      // Set content type
      if (!config.bypassContentType) {
        req = req.clone({
          headers: req.headers.set('Content-Type', 'application/json'),
        });
      }
      if (config.urlEncodedContentType) {
        req = req.clone({
          headers: req.headers.set(
            'Content-Type',
            'application/x-www-form-urlencoded;charset=UTF-8'
          ),
        });
      }

      // Set Accept Type
      req = req.clone({
        headers: req.headers.set('Accept', 'application/json'),
      });

      // set bearer token or refresh token
      if (accessToken) {
        req = req.clone({
          headers: req.headers.set('authorization', `Bearer ${accessToken}`),
        });
      }
      return req;
    }
  }

  /**
   * Method to remove heading and tailing slashes.
   *
   * @param url
   * @returns url with removed slashes.
   */
  /* istanbul ignore next */
  private readonly removeSlashes = (url: string): string => {
    if (url && url.startsWith('/')) {
      url = url.slice(1, url.length);
    } else if (url && url.endsWith('/')) {
      url = url.slice(0, url.length - 1);
    }
    return url;
  };

  /**
   * Method to start the preloader.
   *
   * @param noLoader
   */
  /* istanbul ignore next */
  private requestInterceptor(noLoader: any): void {
    if (!noLoader) {
      this.preloader.start();
    }
  }

  /**
   * Method to stop preloader.
   *
   * @param event
   * @param config
   */
  /* istanbul ignore next */
  private stopPreloaderForResponse(event: HttpEvent<any>, config: any): void {
    if (event instanceof HttpResponse && !config.noLoader) {
      this.preloader.stop();
    }
  }

  /**
   * Method to handle HTTP errors
   *
   * @param error
   * @param config
   */
  /* istanbul ignore next */
  private onSubscribeError(error: any, config: any): Observable<HttpEvent<any>> {
    if (error.status === 0 && !error.ok) {
      // Check internet status
      if (!navigator.onLine) {
        this.notify.show(ToastTypes.Error, NotificationsHeading.ERROR, 'MESSAGE.CHECK_YOUR_INTERNET_CONNECTION');
      } else {
        this.notify.show(
          ToastTypes.Error,
          NotificationsHeading.ERROR,
          this.appConstants.commonAPIErrorMessage
        );
        return of({}) as Observable<HttpEvent<any>>;
      }
    } else if (error.status === HttpErrorCode.UNPROCESSABLE_ENTITY) {
      // Handle 422 request errors.
      this.handle422Error(error.error.errors);
    } else {
      if (!config.noNotifyError) {
        // Show server error messages.
        if (error.error && error.error[this.errorMessageKey]) {
          this.notify.show(ToastTypes.Error, NotificationsHeading.ERROR, error.error[this.errorMessageKey]);
        } else if (error.error?.isLoggedIn) {
          this.notify.show(ToastTypes.Error, NotificationsHeading.ERROR, this.appConstants.commonAPIErrorMessage);
        }
      }
    }
    return throwError(() => new Error(error));
  }

  /**
   * Method to handle 422 HTTP errors.
   *
   * @param error
   */
  /* istanbul ignore next */
  private handle422Error(error: any): void {
    Object.keys(error).forEach((e) => {
      this.notify.show(ToastTypes.Error, NotificationsHeading.ERROR, error[e][0]);
    });
  }
}
