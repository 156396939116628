import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { AppComponent } from './app.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: '/clients' },
  { path: '', component: AppComponent, canActivate: [OktaAuthGuard] },
  { path: 'login/callback', component: OktaCallbackComponent },
  { path: 'clients', loadChildren: () => import('./features/clients/clients.module').then(m => m.ClientsModule), canActivate: [OktaAuthGuard] },
  { path: 'assessment', loadChildren: () => import('./features/assessment/assessment.module').then(m => m.AssessmentModule), canActivate: [OktaAuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
