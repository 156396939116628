import { Component, Input } from '@angular/core';
// eslint-disable-next-line no-unused-vars
import { RatingColor } from '@config/app.config';

@Component({
  selector: 'sra-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent {
  @Input() label: string | undefined = undefined;
  // @Input() type: RatingColor = 'gray';
  @Input() type: 'yellow' | 'blue' | 'gray' | 'green' | 'red' | 'golden' | 'orange' | 'lightGreen' =
    'gray';
}
