import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { IconsLibraryComponent } from './components/icons-library/icons-library.component';
import { CustomInterceptor } from './interceptors/custom.interceptor';
import { PreloaderComponent } from './components/pre-loader/pre-loader.component';
import { AssessmentService, ConnectionService, IndexedDbService, NotifyService } from './services';

export const createTranslateLoader = (
  http: HttpClient
): TranslateHttpLoader => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
@NgModule({
  declarations: [
    HeaderComponent,
    IconsLibraryComponent,
    PreloaderComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true,
    },
    TranslateService,
    AssessmentService,
    ConnectionService,
    IndexedDbService,
    NotifyService
  ],
  exports: [
    HeaderComponent,
    IconsLibraryComponent,
    PreloaderComponent,
    SharedModule,
    TranslateModule,
  ],
})
export class CoreModule {}
