import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConnectionService, NotifyService } from '@core/services';
import { Notifications, NotificationsHeading, NumberConstants, ToastTypes } from '../../../configs/app.config';

@Component({
  selector: 'sra-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() editMode!: boolean;
  @Input() notes!: string;
  @Input() url!: string;
  @Output() fileChanged = new EventEmitter<string>();
  @Output() fileTypeChanged = new EventEmitter<string>();
  @Output() inputChanged = new EventEmitter<string>();
  @Output() editModeChanged = new EventEmitter<boolean>();
  imageUrl = '';
  fileType = '';
  acceptedFileTypes = ["image/png", "image/jpeg"]
  connectionStatus = true;

  constructor(
    private readonly notifyService: NotifyService,
    private readonly connectionService: ConnectionService,
    private readonly destroyRef: DestroyRef
  ) {

  }

  ngOnInit() {
    if (this.notes?.length > 1 || this.url?.length > 1) {
      this.editMode = false;
      this.editModeChanged.emit(this.editMode);
    }
    this.connectionService.getConnectionStatus().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(status => {
      this.connectionStatus = status;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['notes']) {
      this.notes = changes['notes'].currentValue;
    } else if (changes['url']) {
      this.url = changes['url'].currentValue;
    } else if (changes['editMode']) {
      this.editMode = changes['editMode'].currentValue;
    }
  }

  openFileBrowser(): void {
    this.fileInput.nativeElement.click();
  }

  onFilesSelected(event: Event): void {
    const inputEvent = event.target as HTMLInputElement;
    if (inputEvent.files && inputEvent.files[0]) {
      const file = inputEvent.files[0];
      const fileSize = file.size;
      this.fileType = file.type;
      const fileSizeInMb = fileSize / NumberConstants.FILE_SIZE_CALCULATION;
      if (fileSizeInMb > NumberConstants.MAX_FILE_SIZE) {
        this.notifyService.show(ToastTypes.Error, NotificationsHeading.ERROR, Notifications.FILE_SIZE_EXCEEDED);
      } else if (!this.acceptedFileTypes.includes(this.fileType)) {
        this.notifyService.show(ToastTypes.Error, NotificationsHeading.ERROR, Notifications.FILE_TYPE_DIFFERENT);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result as string;
          this.imageUrl = base64String;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  removeImage(): void {
    // Revoke the object URL to free up memory
    URL.revokeObjectURL(this.imageUrl);
    this.imageUrl = '';
    this.url = '';
    this.fileType = ''
    this.fileInput.nativeElement.value = '';
    this.fileChanged.emit(this.imageUrl);
    this.fileTypeChanged.emit(this.fileType);
  }

  handleUserInput(input: string) {
    this.inputChanged.emit(input);
  }

  handleSave() {
    this.fileChanged.emit(this.imageUrl);
    this.fileTypeChanged.emit(this.fileType);
    this.url = this.imageUrl;
    this.imageUrl = '';
    this.editMode = false;
    this.editModeChanged.emit(this.editMode);
  }

  toggleEditMode() {
    if (this.url && this.url.length > 1) {
      this.imageUrl = this.url;
    }
    this.editMode = !this.editMode;
    this.editModeChanged.emit(this.editMode);
  }

  handleCancel() {
    if (this.url && this.url.length > 1) {
      this.toggleEditMode();
    } else {
      this.imageUrl = '';
      this.fileInput.nativeElement.value = '';
      this.editMode = true;
      this.editModeChanged.emit(this.editMode);
    }
  }

  handleDelete() {
    this.url = '';
    this.notes = '';
    this.imageUrl = '';
    this.fileType = '';
    this.inputChanged.emit(this.notes);
    this.fileTypeChanged.emit(this.fileType);
    this.fileChanged.emit(this.url);
    this.editMode = true;
    this.editModeChanged.emit(this.editMode);
  }
}
