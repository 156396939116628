import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {
  public status: Subject<any> = new Subject();
  public message: string;
  private _active = false;
  private readonly defaultMessage = 'Loading...';
  private counter = 0;

  constructor(@Inject(DOCUMENT) public document: Document,) {
    this.message = this.defaultMessage;
  }

  /**
   * Method to get status of the preloader
   *
   * @memberOf Preloader
   */
  /* istanbul ignore next */
  public get active(): boolean {
    return this._active;
  }

  /**
   * Method to set the preloader status to true/false
   *
   *
   * @memberOf Preloader
   */
  /* istanbul ignore next */
  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  /**
   * Method to start preloader by setting status to true
   *
   *
   * @memberOf Preloader
   */
  /* istanbul ignore next */
  public start(m?: string): any {
    this.counter++;
    this.document.body.classList.add('c-api-preloader--shown');
    if (m) {
      this.message = m;
    } else if (this.message) {
      // Do nothign and continue the loop
    } else {
       this.message = this.defaultMessage;
    }
    this.active = true;
  }

  /**
   * Method to stop preloader by setting status to false
   *
   *
   * @memberOf Preloader
   */
  /* istanbul ignore next */
  public stop(): void {
    this.counter--;
    if (this.counter <= 0) {
      this.document.body.className = this.document.body.className.replace(
        'c-api-preloader--shown',
        ''
      );
      this.resetMessage();
      this.active = false;
      this.counter = 0;
    }
  }

  /**
   * Method to set message of the preloader, if message is not passed with start method
   *
   *
   * @memberOf Preloader
   */
  /* istanbul ignore next */
  public setMessage(message: any): void {
    this.message = message;
  }

  /**
   * Method to reset the message
   *
   *
   * @memberOf Preloader
   */
  /* istanbul ignore next */
  public resetMessage(): void {
    this.message = '';
  }
}
