import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from './components/buttons/button/button.component';
import { IconButtonComponent } from './components/buttons/icon-button/icon-button.component';
import { CheckboxComponent } from './components/form-elements/checkbox/checkbox.component';
import { InputFieldComponent } from './components/form-elements/input-field/input-field.component';
import { RadioButtonComponent } from './components/form-elements/radio-button/radio-button.component';
import { SearchFieldComponent } from './components/form-elements/search-field/search-field.component';
import { TextAreaComponent } from './components/form-elements/text-area/text-area.component';
import { RatingDisplayComponent } from './components/rating-display/rating-display.component';
import { SvgIconsComponent } from './components/svg-icons/svg-icons.component';
import { TagsComponent } from './components/tags/tags.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { PopupComponent } from './components/popup/popup.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RatingBarComponent } from './components/rating-bar/rating-bar.component';
import { SiteDetailsLabelPipe } from './pipes/site-details-label.pipe';




@NgModule({
  declarations: [
    TagsComponent,
    SvgIconsComponent,
    SearchFieldComponent,
    RatingDisplayComponent,
    CheckboxComponent,
    InputFieldComponent,
    IconButtonComponent,
    ButtonComponent,
    FileUploadComponent,
    TextAreaComponent,
    RadioButtonComponent,
    ButtonComponent,
    PopupComponent,
    ProgressBarComponent,
    RatingBarComponent,
    SiteDetailsLabelPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
  ],
  exports: [
    TagsComponent,
    SvgIconsComponent,
    SearchFieldComponent,
    RatingDisplayComponent,
    CheckboxComponent,
    InputFieldComponent,
    IconButtonComponent,
    ButtonComponent,
    FileUploadComponent,
    TextAreaComponent,
    RadioButtonComponent,
    ButtonComponent,
    PopupComponent,
    ProgressBarComponent,
    RatingBarComponent,
    SiteDetailsLabelPipe
  ]
})
export class SharedModule { }
