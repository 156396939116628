<div class="app-c-rating-component">
    <div class="app-c-rating-svg">
        <svg viewBox="0 0 67 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M66.0898 33.0449C66.0898 26.0665 63.8806 19.2672 59.7788 13.6216L51.7586 19.4486C54.6299 23.4005 56.1763 28.16 56.1763 33.0449H66.0898Z"
                fill="#00B524" />
            <path
                d="M59.7788 13.6216C55.677 7.97595 49.8932 3.77378 43.2563 1.61733L40.1929 11.0456C44.8387 12.5551 48.8874 15.4966 51.7586 19.4486L59.7788 13.6216Z"
                fill="#7FC314" />
            <path
                d="M43.2563 1.61733C36.6195 -0.539111 29.4703 -0.539111 22.8335 1.61733L25.8969 11.0456C30.5427 9.53609 35.5471 9.53609 40.1929 11.0456L43.2563 1.61733Z"
                fill="#FECF1D" />
            <path
                d="M22.8335 1.61733C16.1966 3.77378 10.4128 7.97596 6.31101 13.6216L14.3312 19.4486C17.2024 15.4966 21.2511 12.5551 25.8969 11.0456L22.8335 1.61733Z"
                fill="#FF9B13" />
            <path
                d="M6.31101 13.6216C2.20921 19.2672 6.10071e-07 26.0665 0 33.0449H9.91347C9.91347 28.16 11.4599 23.4005 14.3312 19.4486L6.31101 13.6216Z"
                fill="#FE4330" />
            <path
                d="M47.6676 33C47.6676 29.0129 46.0837 25.1891 43.2644 22.3698C40.4451 19.5505 36.6213 17.9667 32.6343 17.9667C28.6472 17.9667 24.8234 19.5505 22.0041 22.3698C19.1848 25.1891 17.6009 29.0129 17.6009 33L18.3732 33C18.3732 29.2177 19.8757 25.5903 22.5501 22.9159C25.2246 20.2414 28.852 18.7389 32.6343 18.7389C36.4165 18.7389 40.0439 20.2414 42.7184 22.9159C45.3929 25.5904 46.8954 29.2177 46.8954 33L47.6676 33Z"
                fill="#D9D9D9" />
            <path
                d="M41.0662 33C41.0662 30.8606 40.1777 28.8088 38.5961 27.296C37.0146 25.7832 34.8695 24.9333 32.6329 24.9333C30.3962 24.9333 28.2512 25.7832 26.6696 27.296C25.088 28.8088 24.1995 30.8606 24.1995 33L24.6327 33C24.6327 30.9705 25.4756 29.0241 26.9759 27.589C28.4762 26.1539 30.5111 25.3477 32.6329 25.3477C34.7546 25.3477 36.7895 26.1539 38.2898 27.589C39.7901 29.0241 40.633 30.9705 40.633 33L41.0662 33Z"
                fill="#D9D9D9" />
            <!-- Needle -->
            <g [attr.transform]="'rotate(' + needleAngle + ', 32.6343, 33)'">
                <path d="M56.9354 17.9258L34.9174 34.6202L33.0569 31.8296L56.9354 17.9258Z" fill="#272525" />
                <path
                    d="M35.5035 34.4043C34.6661 34.9626 33.5345 34.7363 32.9762 33.8988C32.4179 33.0614 32.6442 31.9298 33.4816 31.3715C34.3191 30.8132 35.4507 31.0395 36.009 31.8769C36.5673 32.7144 36.341 33.846 35.5035 34.4043Z"
                    fill="#272525" />
            </g>
        </svg>
    </div>
</div>
