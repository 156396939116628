import { Injectable } from '@angular/core';
import { IOctaUserModel, IUser } from '@core/models/octa.model';
import { BehaviorSubject } from 'rxjs';
import { LocalStoreService } from './localstore.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loggedUser: BehaviorSubject<IUser> = new BehaviorSubject<IUser>({
    name: '',
    email: ''
  });

  constructor(
    private readonly localStore: LocalStoreService
  ) { }


  onLoginSuccess(user : IOctaUserModel): void {
    this.loggedUser.next({
      name: user.name,
      email: user.email
    })
  }
}
