<button [id]="id" [attr.aria-label]="label || ariaLabel" [disabled]="disabled" class="app-c-icon-btn" [ngClass]="{
    'xs': size === 'xs',
    'sm': size === 'sm',
    'md': size === 'md',
    'lg': size === 'lg',
    'xl': size === 'xl',

    'primary': hierarchy === 'primary',
    'secondary': hierarchy === 'secondary',
    'link': hierarchy === 'link',
    'left' : iconPlacement === 'left',
    'right' : iconPlacement === 'right',
  }"
  (click)="handleClick($event)"
  >
    <!-- <ng-container *ngIf="iconName">
        
    </ng-container> -->
    <ng-content></ng-content>
        <span *ngIf="label">{{label}}</span>

</button>