import { Injectable } from '@angular/core';
import { isNull } from 'lodash-es';
import { environment } from '@environment/environment';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {

  constructor(private readonly cryptoService: CryptoService) { }

  /**
   * This method is used to store the data to the local storage after encryption
   *
   * @param key key corresponds to the data stored
   * @param data data to be stored
   */
  store(key: any, data: any) {
    const keyHashed = this.getOriginalHashedKey(key);
    let encryptedData;
    if (environment.production) {
      encryptedData = this.cryptoService.encrypt(JSON.stringify(data)).toString();
    } else {
      encryptedData = JSON.stringify(data);
    }
    window.localStorage.setItem(keyHashed, encryptedData);
  }

  /**
   * This method is used to get the data from the local storage after decryption
   *
   * @param key is the key that corresponds the data stored
   * @returns Original data
   */
  get(key: any) {
    const keyHashed = this.getOriginalHashedKey(key);
    const encryptedData = window.localStorage.getItem(keyHashed);
    let data = null;
    if (!isNull(encryptedData)) {
      if (environment.production) {
        data = JSON.parse(this.cryptoService.decrypt(encryptedData));
      } else {
        data = JSON.parse(encryptedData);
      }
    }
    return data;
  }

  /**
   * This method to return the hashed key
   *
   * @param key key to be hashed
   * @returns the hashed key
   */
  getOriginalHashedKey(key: any) {
    if (environment.production) {
      return this.cryptoService.md5(key);
    } else {
      return key
    }
  }

  /**
   * This method is used to clear the data from storage on logout
   */
  clear() {
    sessionStorage.clear() // To clear the session storage on logging out
    localStorage.clear() // To clear the local storage on logging out
  }
}

