import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environment/environment';
import { TranslateService } from '@ngx-translate/core';
import { AppInitializationService } from '@core/services';
import { OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

// Function to initialize the app
export const initializeApp = (
  appInitializationService: AppInitializationService
) => {
  return (): Promise<any> => {
    return appInitializationService.getSettings(); // Ensure this returns a Promise
  };
};

export const oktaAuth = new OktaAuth({
  issuer: environment.okta.issuer,
  clientId: environment.okta.clientId,
  redirectUri: window.location.origin + environment.okta.redirectUri,
  // scopes: ['openid', 'offline_access', 'email'],
  // pkce: true,
});

// schema for indexeddb
const dbConfig: DBConfig = {
  name: 'OfflineAssessmentDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'assessmentDetails',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [],
    },
    {
      store: 'assessmentForm',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [],
    },
    {
      store: 'assessmentScore',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [],
    },
    {
      store: 'siteDetails',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [],
    },
  ],
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    NgxIndexedDBModule.forRoot(dbConfig),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializationService],
      multi: true,
    },
    TranslateService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
