<div class="popup-overlay" *ngIf="isVisible" (click)="closePopup()">
  <div
    class="popup-container"
    (click)="$event.stopPropagation()"
    [ngClass]="{ 'image-popup': isImagePopup }"
  >
    <div class="popup-header" *ngIf="showHeader">
      <h3 class="popup-title">{{ title }}</h3>
      <sra-icon-button size="sm">
        <sra-svg-icons
          name="close"
          title="Close Popup"
          className="icon-attachment icon-size-12"
          ariaHidden="false"
          focusable="false"
          (click)="closePopup()"
        />
      </sra-icon-button>
    </div>
    <span *ngIf="isImagePopup" class="image-close-btn">
      <sra-icon-button size="xs">
        <sra-svg-icons
          name="close"
          title="Close Popup"
          className="icon-white icon-size-10"
          ariaHidden="false"
          focusable="false"
          (click)="closePopup()"
        />
      </sra-icon-button>
    </span>
    <div class="popup-body">
      <ng-content></ng-content>
    </div>
    <div class="popup-footer" *ngIf="showFooter && !showOk">
      <sra-button
        id="cancel"
        hierarchy="secondary"
        (click)="closePopup()"
        ariaLabel="Cancel"
        label="Cancel"
        size="sm"
      ></sra-button>
      <sra-button
        id="clear"
        hierarchy="primary"
        (click)="emitValue()"
        ariaLabel="Clear"
        [label]="value"
        size="sm"
        [disabled]="false"
      ></sra-button>
    </div>

    <div class="popup-footer" *ngIf="showFooter && showOk">
      <sra-button
        id="cancel"
        hierarchy="primary"
        (click)="emitValue();closePopup()"
        ariaLabel="cancel"
        [label]="'PAGE.LABELS.OK' | translate"
        size="sm"
        [disabled]="false"
      ></sra-button>
    </div>
  </div>
</div>
