import { Component, OnInit } from '@angular/core';
import { IUser } from '@core/models/octa.model';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'sra-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedInUser! : IUser;
  constructor(
    public authService: AuthService
  ) {

  }
  ngOnInit(): void {
  this.authService.loggedUser.subscribe((user: IUser) => {
    this.loggedInUser = user;
  })
  }

}
