<div class="app-c-rating-group" [ngClass]="customClass">
  <label class="app-c-rating-option" *ngFor="let value of [1, 2, 3, 4, 5]">
    <input
      class="app-c-rating-input"
      (change)="onRadioChange($event)"
      type="radio"
      [name]="name"
      [value]="value"
      [checked]="value === checkedValue"
      [disabled]="disabled"
    />
    <span class="checkmark"></span>
    <span class="app-c-rating-label">{{ value }}</span>
  </label>
</div>
<div class="app-c-rating-footer" *ngIf="showFooter">
  <span class="app-c-rating-indication">
    <sra-svg-icons
      name="dislike"
      title="Poor"
      className="icon-size-12"
      ariaHidden="false"
      focusable="false"
    />
    Poor
  </span>
  <span class="app-c-rating-indication">
    <sra-svg-icons
      name="like"
      title="Excellent"
      className="icon-size-12"
      ariaHidden="false"
      focusable="false"
    />

    Excellent
  </span>
</div>
