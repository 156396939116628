<span class="app-c-tag" [ngClass]="{

    'yellow': type === 'yellow',
    'blue': type === 'blue',
    'gray': type === 'gray',
    'green': type === 'green',
    'red': type === 'red',
    'golden': type === 'golden',
    'orange': type === 'orange',
    'lightGreen': type === 'lightGreen'
  }">
    {{label}}
</span>