import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, map, merge, Observable, of, Subscription } from 'rxjs';
import { Notifications, NotificationsHeading, ToastTypes } from '../../configs/app.config';
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService implements OnDestroy {
  private connectionStatus$!: Observable<boolean>;
  private readonly subscription: Subscription = new Subscription();

  constructor(private readonly notifyservice: NotifyService) {
    this.setupConnectionCheck();
  }

  //function to setup connection status check
  private setupConnectionCheck(): void {
    this.connectionStatus$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(
      map(() => navigator.onLine)
    );
    this.subscription.add(
      this.connectionStatus$.subscribe((status) => {
        if (status) {
          this.notifyservice.show(ToastTypes.Success, NotificationsHeading.ONLINE, Notifications.ONLINE);
        } else {
          this.notifyservice.show(ToastTypes.Warning, NotificationsHeading.OFFLINE, Notifications.OFFLINE);
        }
      })
    );
  }

  // function to expose connection status as observable
  getConnectionStatus(): Observable<boolean> {
    return this.connectionStatus$;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
