import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IClientSearch, IHubspotData } from '@core/models/client.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private readonly httpClient: HttpClient
  ) { }

  // Method to get client details
  /* istanbul ignore next */
  getClientDetails(requestData: IClientSearch): Observable<IHubspotData> {
    return this.httpClient.post<IHubspotData>('hubspot-details', requestData, {
      params: {
        _config: JSON.stringify({ noNotifyError: false })
      }
    });
  }
}
