<button
  [id]="id"
  [attr.aria-label]="label || ariaLabel"
  [disabled]="disabled"
  class="app-c-btn"
  [ngClass]="{
    'xs': size === 'xs',
    'sm': size === 'sm',
    'md': size === 'md',
    'lg': size === 'lg',
    'xl': size === 'xl',

    'primary': hierarchy === 'primary',
    'secondary': hierarchy === 'secondary',
    'link': hierarchy === 'link',
  }"
>
  <span>{{label}}</span>
  <ng-container *ngIf="iconName">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <use [attr.xlink:href]="'#icon-' + iconName"></use>
    </svg>
  </ng-container>  
</button>
