<div class="app-c-header">
    <a href="#" class="app-l-header-logo">
        <img src="assets/images/FlagshipLabServicesLogo.svg" alt="Flagship Lab Services" />
    </a>
    <h1 class="app-c-header-title" [innerHTML]="'PAGE.TITLES.SCIENCE_READY_ASSESSMENT' | translate"></h1>
    <div class="app-l-profile-section">
        <div class="app-l-profile-avatar">
            <img src="assets/images/profile-avatar.png" alt="Angelica" />
        </div>
        <span class="app-l-profile-username">{{loggedInUser.name}}</span>
    </div>
</div>
