<div class="app-c-text-area" [ngClass]="customClass">
  <textarea
  [value]="value"
    [id]="id"
    [attr.placeholder]="placeholder"
    [disabled]="disabled"
    #textareaRef
    (input)="onInput($event);adjustHeight()"
  ></textarea>
  <ng-content></ng-content>
</div>
