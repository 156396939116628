import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sra-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent {
  @Input() id: string | undefined = undefined;
  @Input() type: string | undefined = undefined;
  @Input() placeholder: string | undefined = undefined;
  @Input() disabled = false;
  @Input() customClass: string | undefined = undefined;
  @Input() value?: string;
  @Output() userInput = new EventEmitter<string>();

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    this.userInput.emit(input.value);
  }
}
