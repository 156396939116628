import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Constants {
  notificationTimeout = 7000;
  commonAPIErrorMessage = 'Server Error';
  key = 'R876BNKY654ERTYU890JHGY76R5RFGHJOIUYGNS';
  iv = 'NMKLOIUYTHJHF543456789P0987YTGFC';
  defaultLanguage = 'en';
  minSraScore = 0;
  maxSraScore = 5;
  clientDetailsPageSize = 10;
  minSearchLength = 2;
}

export enum HttpErrorCode {
  FORBIDDEN = 403,
  UNPROCESSABLE_ENTITY = 422,
}

export enum ToastTypes {
  Success = 0,
  Warning = 1,
  Error = 2,
  Info = 3,
}

@Injectable({
  providedIn: 'root',
})
export class QuestionTypes {
  CHECKBOX = 'Checkbox';
  RADIO = 'RadioButton';
  TEXT = 'InputText';
  RATING = 'RatingScale';
  TEXT_AREA = 'TextArea'
}

export enum QuestionFormKeys {
  QUESTION_ID = 'questionID',
  QUESTION_ORDER = 'questionOrder',
  QUESTION_TYPE = 'questionType',
  QUESTION_TEXT = 'questionText',
  ANSWER = 'answer',
  ANSWER_TEXT = 'answerText',
  ATTACHMENTS = 'attachments',
  ATTACHMENT_NOTES = 'attachmentNotes',
  ATTACHMENT_ID = 'attachmentID',
  FILE_PATH = 'filePath',
  FILE_DATA = 'fileData',
  FILE_TYPE = 'fileType',
  OPTIONS = 'options',
  OPTION_TEXT = 'optionText',
  SEPARATOR = ',',
  ATTACHMENT_REQUIRED = 'isAttachmentRequired'
}

export enum AssessmentFormKeys {
  SECTIONS = 'sections',
  SECTION_NAME = 'sectionName',
  QUESTIONS = 'questions',
  TAB_NAME = 'tabName',
  TABS = 'tabs',
}

export enum TabNames {
  OVERVIEW = 'Overview',
  GENERAL_INFORMATION = 'General lab',
  FACILITY_CONDITION = 'Facility condition assessment',
}

export enum SectionNames {
  LOGISTICS = 'Logistics',
  EQUIPMENT = 'Equipment',
  CONSUMABLES = 'Consumables/procurement',
  CHEMICALS = 'Chemicals',
  SITE_DETAILS = 'Site details',
}

export enum WeightedPercentage {
  LOGISTICS = 0.3,
  EQUIPMENT = 0.1,
  CONSUMABLES = 0.25,
  CHEMICALS = 0.35,
}

export enum LocalStorageKeys {
  ASSESSMENT_STATUS = 'assessment_status',
  ASSESSMENT_PROPERTIES = 'assessmentProperties',
  LoggedInUserDetails = 'loggedInUserDetails',
  AccessToken = 'accessToken',
  ViewOnlyMode = 'viewOnlyMode',
}

export interface INotify {
  heading: string;
  message: string;
}

export enum NotificationsHeading {
  OFFLINE = "Offline",
  ONLINE = "Online",
  ERROR = "Error",
  SUCCESS = "Success",
  WARNING = "Warning",
  INFO = "Info",
  SUBMIT_SUCCESS = "Submitted",
  SAVE_SUCCESS = "Saved",
  SAVE_NONE = "No Changes",
  CLEAR_FORM_SUCCESS = "Cleared",
  ASSESSMENT_SYNCING = "Processing Submission",
  IMAGE_UPLOADED_SUCCESSFULLY = "Uploaded",
  IMAGE_DELETED_SUCCESSFULLY = "Deleted",
}

export enum Notifications {
  OFFLINE = "You're offline. Please check your connection",
  ONLINE = "You're back Online.",
  SUBMIT_SUCCESS = "Assessment submitted successfully.",
  SUBMIT_FAILED = "Failed to submit assessment.",
  SAVE_SUCCESS = "Changes saved successfully.",
  SAVE_FAILED = "Failed to save changes.",
  SAVE_NONE = "No updates to save.",
  CLEAR_FORM_SUCCESS = "Assessment form cleared successfully.",
  ASSESSMENT_SYNCING = "Your submission is in progress.",
  FILE_SIZE_EXCEEDED = "Exceeded maximum allowed file size of 1MB.",
  FILE_TYPE_DIFFERENT = "Upload an image of type .png or .jpeg",
  IMAGE_UPLOADED_SUCCESSFULLY = "Image uploaded successfully",
  IMAGE_DELETED_SUCCESSFULLY = "Image deleted successfully",
}

export enum UserSelection {
  CLEAR = 'Clear',
  SUBMIT = 'Submit',
  OK = 'OK',
}

export enum DBStoreNames {
  ASSESSMENT_FORM = 'assessmentForm',
  ASSESSMENT_DETAILS = 'assessmentDetails',
  ASSESSMENT_SCORE = 'assessmentScore',
  SITE_DETAILS = 'siteDetails',
}

export enum NumberConstants {
  GENERAL_TAB_INDEX = 1,
  SITE_DETAILS_LENGTH = 6,
  FILE_SIZE_CALCULATION = 1024 ** 2,
  STEP_COUNT = 1,
  DEFAULT_MAIN_TAB_INDEX = 0,
  DEFAULT_FORM_ID = 1,
  MIN_NAMES_LENGTH = 1,
  MIN_NOTES_LENGTH = 1,
  MIN_URL_LENGTH = 1,
  MIN_ANSWER_LENGTH = 0,
  IMAGE_CHUNKS_LENGTH = 4,
  MIN_CHUNKS_LENGTH = 0,
  MIN_SECTIONS_LENGTH = 0,
  MAX_FILE_SIZE = 5
}

export enum APIResponseStrings {
  ATTACHMENT_UPLOAD_SUCCESS = "Attachments uploaded and processed successfully.",
  ASSESSMENT_SYNC_SUCCESS = "Assessment data synchronized successfully.",
  DRAFT_SYNC_SUCCESS = "Your draft answers were saved successfully."
}

export interface IRatingLabel {
  label: string;
  color: RatingColor;
  greaterThan: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
// export const RATINGS: IRatingLabel[] = [
//   {
//     label: 'Excellent',
//     color: 'green',
//     greaterThan: 4,
//   },
//   {
//     label: 'Good',
//     color: 'light-green',
//     greaterThan: 3,
//   },
//   {
//     label: 'Adequate',
//     color: 'yellow',
//     greaterThan: 2,
//   },
//   {
//     label: 'Marginal',
//     color: 'golden',
//     greaterThan: 1,
//   },
//   {
//     label: 'Poor',
//     color: 'red',
//     greaterThan: 0,
//   },
// ];

export type RatingColor =
  | 'yellow'
  | 'blue'
  | 'gray'
  | 'green'
  | 'red'
  | 'golden'
  | 'light-green';

export enum AppROUTES {
  ASSESSMENT = '/assessment',
  HOME = '/',
  OVERALL_SCORE = '/assessment/overall-score',
  CLIENTS = '/clients'
}

export enum SiteDetailsLabels {
  CLIENT_NAME = 'clientName',
  COMPANY_ADDRESS = 'companyAddress',
  CLIENT_CONTACT = 'clientContact',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  SITE = 'site',
}

export enum AssessmentStatus {
  ReadyForAssessment = 'READY FOR ASSESSMENT',
  InProgress = 'IN PROGRESS',
  Completed = 'COMPLETED',
}

export const rangeConfig = [
  { min: 0, max: 1.0, colorClass: 'red', label: 'Poor' },
  { min: 1.01, max: 2.0, colorClass: 'orange', label: 'Marginal' },
  { min: 2.01, max: 3.0, colorClass: 'yellow', label: 'Adequate' },
  { min: 3.01, max: 4.0, colorClass: 'lightGreen', label: 'Good' },
  { min: 4.01, max: 5.0, colorClass: 'green', label: 'Excellent' },
]
