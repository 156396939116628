import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'sra-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements AfterViewInit {
  @Input() id: string | undefined = undefined;
  @Input() placeholder: string | undefined = undefined;
  @Input() disabled = false;
  @Input() customClass: string | undefined = undefined;
  @Input() value!: string;
  @Input() height?:string = '42px';
  @Output() textChanged = new EventEmitter<string>();

  @ViewChild('textareaRef') textarea!: ElementRef<HTMLTextAreaElement>;

  ngAfterViewInit() {
    this.adjustHeight(); // Adjust height initially if there's any pre-filled content
  }

  onInput(event: Event) {
    const textArea = event.target as HTMLTextAreaElement;
    this.textChanged.emit(textArea.value);
  }

  adjustHeight() {
    const textareaEl = this.textarea.nativeElement;
    textareaEl.style.height = `${this.height}`; // Reset the height
    textareaEl.style.height = `${textareaEl.scrollHeight}px`; // Set height to scrollHeight
  }
}
