<ng-container *ngIf="editMode">
  <!-- Hidden file input allowing multiple file selection -->
  <input type="file" accept="image/png, image/jpeg" #fileInput style="display: none"
    (change)="onFilesSelected($event)" />

  <!-- Button to trigger the file input -->
  <!-- <button type="button" (click)="openFileBrowser()">Upload Images</button> -->

  <!-- Display the selected images with remove buttons -->
  <sra-input-field [value]="notes" (userInput)="handleUserInput($event)" type="text"
    [placeholder]="'PAGE.LABELS.ADD_ATTACHMENT_OR_NOTES' | translate" [customClass]="'has-action'">
    <span class="action-button" *ngIf="connectionStatus">
      <sra-icon-button size="sm" (buttonClick)="openFileBrowser()">
        <sra-svg-icons name="attachment" title="Add attachment" className="icon-attachment icon-size-12"
          ariaHidden="false" focusable="false" />
      </sra-icon-button>
    </span>
    <div class="app-c-file-upload-wrapper" *ngIf="imageUrl && imageUrl.length > 0">
      <div class="selected-image-stack">
        <div class="selected-image-wrapper">
          <img class="selected-image" [src]="imageUrl" alt="Selected Image" />
          <span class="selected-image-actions">
            <sra-icon-button size="sm" (buttonClick)="removeImage()">
              <sra-svg-icons name="delete" title="Remove attachment" className="icon-delete icon-size-24"
                ariaHidden="false" focusable="false" />
            </sra-icon-button>
          </span>
        </div>
      </div>
    </div>
  </sra-input-field>
</ng-container>
<div class="view-only-section" *ngIf="!editMode">
  <h5 *ngIf="notes && notes.length > 1">Note</h5>
  <p *ngIf="notes && notes.length > 1" class="view-only-data">
    {{ notes }}
  </p>
  <div class="view-image-stack" *ngIf="url && url.length > 0">
    <div class="view-image-wrapper">
      <img class="view-image" [src]="url" alt="view Image" />
      <span class="view-image-actions">
        <sra-icon-button size="sm">
          <sra-svg-icons name="eye" title="view attachment" className="icon-eye icon-size-24" ariaHidden="false"
            focusable="false" (click)="popup.openPopup()" />
        </sra-icon-button>
      </span>
      <sra-popup #popup [showHeader]="false" [showFooter]="false" [isImagePopup]="true">
        <img class="view-popup-image" [src]="url" alt="view Image" />
      </sra-popup>
    </div>
  </div>
  <div class="upload-actions-wrapper" *ngIf="!editMode">
    <sra-button id="edit" (click)="toggleEditMode()" hierarchy="link" ariaLabel="edit" label="Edit"
      size="sm"></sra-button>
    <sra-button id="delete" (click)="handleDelete()" hierarchy="link" ariaLabel="Delete" label="Delete"
      size="sm"></sra-button>
  </div>
</div>
<div class="upload-actions-wrapper" *ngIf="editMode && imageUrl.length > 0">
  <sra-button id="save" (click)="handleSave()" hierarchy="link" ariaLabel="Save" label="Save" size="sm"></sra-button>
  <sra-button id="cancel" (click)="handleCancel()" hierarchy="link" ariaLabel="Cancel" label="Cancel"
    size="sm"></sra-button>
</div>