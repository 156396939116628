/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { IColorRange } from '@core/models/client.model';
import { rangeConfig } from '../../configs/app.config';

@Injectable({
  providedIn: 'root'
})
export class RangeMapperService {

  private readonly rangeMap = rangeConfig;

  // Get the mapping for a given value
  getRangeMapping(value: number): IColorRange | undefined {
    return this.rangeMap.find(range => value >= range.min && value <= range.max);
  }
}
