import { Component, Input } from '@angular/core';

@Component({
  selector: 'sra-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() label: string | undefined = undefined;
  @Input() ariaLabel = '';
  @Input() disabled = false;
  @Input() id: string | undefined = undefined;
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() hierarchy: 'primary' | 'secondary' | 'link' | 'white' =
    'primary';
    @Input() iconName: string | undefined = undefined;
  // @Output() buttonClick = new EventEmitter<MouseEvent>();

  // onClickHandler(event: MouseEvent) {
  //   this.buttonClick.emit(event);
  // }
}
