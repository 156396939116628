import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sra-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  @Input() title = 'Popup Title';
  @Input() showHeader = true; // Add an input to control the header
  @Input() showFooter = true; // Add an input to control the footer
  @Input() isImagePopup = false;
  @Input() value?: string;
  @Input() showOk?: boolean;
  @Output() userSelected = new EventEmitter<string>();
  isVisible = false;
  openPopup(): void {
    this.isVisible = true;
  }

  closePopup(): void {
    this.isVisible = false;
  }

  emitValue() {
    this.userSelected.emit(this.value);
    this.closePopup()
  }
}
