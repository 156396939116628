import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '@environment/environment';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializationService {
  private appConfig!: typeof environment;

  /* istanbul ignore next */
  constructor(private readonly httpService: HttpClient, private readonly cryptoService: CryptoService) {
    // Following code is added so that unit test cases does not fail when api base path in appConfig is null
    if (!environment.production) {
      this.appConfig = environment;
    }
  }

  /**
   * Method to load the config.json.
   */
  /* istanbul ignore next */
  getSettings(): Promise<any> {
    return lastValueFrom(
      this.httpService.get(`assets/config/config.json`)
    ).then((settings) => {
      // Decrypt configuration values if production mode else use values from environment file
      if (environment.production) {
        this.appConfig = this.decryptConfigurationValues(settings);
        environment.okta = this.appConfig.okta;
      } else {
        this.appConfig = environment;
      }
      return this.appConfig;
    });
  }

  /**
   * Getter function to appConfig
   */
  /* istanbul ignore next */
  get config() {
    return this.appConfig;
  }

  /**
   * Method to decrypt configuration values
   */
  /* istanbul ignore next */
  decryptConfigurationValues(settings: any) {
    Object.keys(settings).forEach(
      (key) => (settings[key] = this.cryptoService.decrypt(settings[key]))
    );
    return settings;
  }
}
