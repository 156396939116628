import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sra-rating-bar',
  templateUrl: './rating-bar.component.html',
  styleUrls: ['./rating-bar.component.scss'],
})
export class RatingBarComponent {
  @Input() name = '';
  @Input() checkedValue = 0;
  @Input() disabled = false;
  @Input() customClass = '';
  @Input() showFooter = true
  @Output() optionChange = new EventEmitter<string>();

  onRadioChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.checked) {
      this.optionChange.emit(input.value.toString());
    }
  }
}
